<template>
  <div>
    <filter-card
      v-if="!userData.id_customers"
      :get-data-list="getDataList"
    />
    <filter-card-user
      v-else
      :get-data-list="getDataList"
    />
    <b-card no-body>
      <b-card-header>
        <b-card-title>İşlem Geçmişi</b-card-title>
        <b-button
          variant="success"
          pill
          size="sm"
          :href="downloadURL"
          :disabled="dataList.length === 0"
        >
          <FeatherIcon icon="DownloadIcon" />
          Excel Aktar
        </b-button>
      </b-card-header>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        hover
      >
        <template #cell(customer)="data">
          <b-link
            :to="'/payment-history/view/' + data.item.id"
            class="text-body"
          >
            <div class="font-weight-bold">
              {{ data.item.customer }}
            </div>
            <div
              v-if="data.item.customer_user"
              class="font-small-2 text-primary"
            >
              {{ data.item.customer_user }}
            </div>
            <div
              v-else-if="data.item.username"
              class="font-small-2 text-warning"
            >
              {{ data.item.username }}
            </div>
          </b-link>
        </template>
        <template #cell(amount)="data">
          <div class="text-right font-weight-bold text-danger text-nowrap">
            {{ data.item.amount | toCurrency }} TL
          </div>
          <div class="font-small-1 text-secondary">
            {{ data.item.bank }}
          </div>
        </template>
        <template #cell(payment_status)="data">
          <div class="text-center">
            <b-badge
              v-if="data.item.id_payment_statuses === '1'"
              variant="light-info"
            >
              {{ data.item.payment_status }}
            </b-badge>
            <b-badge
              v-else-if="data.item.id_payment_statuses === '2'"
              variant="light-success"
            >
              {{ data.item.payment_status }}
            </b-badge>
            <b-badge
              v-else-if="data.item.id_payment_statuses === '3'"
              variant="light-danger"
            >
              {{ data.item.payment_status }}
            </b-badge>
            <b-badge
              v-else
              variant="light-warning"
            >
              {{ data.item.payment_status }}
            </b-badge>
          </div>
        </template>
        <template #cell(created)="data">
          <div class="text-right">
            <div class="text-nowrap">
              {{ moment(data.item.created).format('DD.MM.YYYY HH:mm') }}
            </div>
          </div>
        </template>
        <template #cell(control)="data">
          <b-dropdown
            text="İşlem Detayı"
            variant="primary"
            size="sm"
            block
          >
            <b-dropdown-item :to="'/payment-history/view/' + data.item.id">
              Görüntüle
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="data.item.result !== '1'"
              :href="`${baseurl}/exports/pdf/payments?token=${downloadToken}&id=${data.item.id}`"
              target="_blank"
            >
              Makbuz Yazdır
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th colspan="2">
              Toplam Tutar
            </b-th>
            <b-th class="text-right text-nowrap text-danger">
              {{ dataTotal | toCurrency }} TL
            </b-th>
            <b-th
              class="text-center"
              colspan="2"
            />
          </b-tr>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BCardFooter,
  BPagination,
  BBadge,
  BDropdown,
  BDropdownItem,
  BButton,
  BTr,
  BTh,
  BLink,
} from 'bootstrap-vue'
import FilterCard from '@/views/PaymentHistory/components/FilterCard.vue'
import FilterCardUser from '@/views/PaymentHistory/components/FilterCardUser.vue'

export default {
  name: 'UserTypes',
  components: {
    FilterCardUser,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BCardFooter,
    BPagination,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    BTr,
    BTh,
    BLink,
    FilterCard,
  },
  data() {
    return {
      baseurl: this.$store.state.app.baseURL,
      downloadToken: localStorage.getItem('downloadToken'),
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      userData: JSON.parse(localStorage.getItem('userData')),
      search: '',
      statusData: [
        { id: 1, title: 'Başarılı' },
        { id: 0, title: 'Başarısız' },
        { id: null, title: 'Eksik İşlem' },
      ],
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ',
        },
        {
          key: 'payment_status',
          label: 'DURUM',
          thClass: 'width-100 text-center text-nowrap',
          tdClass: 'width-100 text-center text-nowrap',
        },
        {
          key: 'amount',
          label: 'İŞLEM TUTARI',
          thClass: 'width-200 text-right',
          tdClass: 'width-200 text-nowrap text-right',
        },
        {
          key: 'created',
          label: 'İŞLEM TARİHİ',
          thClass: 'width-200 text-right text-nowrap',
          tdClass: 'width-200 text-right text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-200 text-right text-nowrap',
          tdClass: 'width-200 text-right text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'payments.id AS id',
          'banks.title AS bank',
          'customers.company AS customer',
          'payments.amount AS amount',
          'payments.result AS result',
          'payment_statuses.title AS payment_status',
          'payments.id_payment_statuses AS id_payment_statuses',
          'users.name AS username',
          'payments.created AS created',
          'customer_users.name AS customer_user',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['paymentsHistory/filterData']
    },
    dataList() {
      return this.$store.getters['paymentsHistory/getPayments_history']
    },
    dataCount() {
      return this.$store.getters['paymentsHistory/getPayments_historyCount']
    },
    dataTotal() {
      return this.$store.getters['paymentsHistory/getPayments_historyTotal']
    },
    banks() {
      return this.$store.getters['posConfigs/getPos_configs']
    },
    downloadURL() {
      const urlParams = []
      if (this.filterData.id_payment_statuses) {
        urlParams.push(`id_payment_statuses=${this.filterData.id_payment_statuses}`)
      }
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_pos_configs) {
        urlParams.push(`id_pos_configs=${this.filterData.id_pos_configs}`)
      }
      if (this.filterData.id_users) {
        urlParams.push(`id_users=${this.filterData.id_users}`)
      }
      if (this.filterData.id_customer_users) {
        urlParams.push(`id_customer_users=${this.filterData.id_customer_users}`)
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/excel/payments?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * this.$store.state.app.perPage
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
    this.getBanks()
    this.getUsers()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'users.id AS id',
          'users.name AS title',
        ],
      })
    },
    getDataList() {
      const query = this.dataQuery
      let where = null
      let result = {}
      // eslint-disable-next-line
      let id_users = {}
      // eslint-disable-next-line
      let id_customer_users = {}
      // eslint-disable-next-line
      let id_pos_configs = {}
      let sdate = {}
      let edate = {}
      if (this.filterData.id_payment_statuses !== null) {
        result = { 'payments.id_payment_statuses': this.filterData.id_payment_statuses }
      }
      if (this.filterData.id_pos_configs !== null) {
        // eslint-disable-next-line camelcase
        id_pos_configs = { 'payments.id_pos_configs': this.filterData.id_pos_configs }
      }
      if (this.filterData.id_users !== null) {
        // eslint-disable-next-line camelcase
        id_users = { 'payments.id_users': this.filterData.id_users }
      }
      if (this.filterData.id_customer_users !== null) {
        // eslint-disable-next-line camelcase
        id_customer_users = { 'payments.id_customer_users': this.filterData.id_customer_users }
      }
      if (this.filterData.sdate !== null) {
        sdate = { 'DATE(payments.created) >=': this.filterData.sdate }
      }
      if (this.filterData.edate !== null) {
        edate = { 'DATE(payments.created) <=': this.filterData.edate }
      }
      where = Object.assign(result, sdate, edate, id_pos_configs, id_users, id_customer_users)
      if (this.filterData.keyword) {
        query.or_like = {
          'customers.company': this.filterData.keyword,
          'customers.phone': this.filterData.keyword,
          'customers.tax_number': this.filterData.keyword,
        }
      } else {
        query.or_like = {}
      }
      query.where = where
      this.$store.dispatch('paymentsHistory/payments_historyList', query)
    },
    getBanks() {
      this.$store.dispatch('posConfigs/pos_configsList', {
        select: [
          'pos_configs.id AS id',
          'banks.title AS title',
        ],
      })
    },
  },
}
</script>
