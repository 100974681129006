<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Banka"
          label-for="id_pos_configs"
        >
          <v-select
            id="id_pos_configs"
            v-model="filterData.id_pos_configs"
            label="title"
            :options="banks"
            placeholder="Seçiniz"
            :reduce="bank => bank.id"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Durum"
          label-for="status"
        >
          <v-select
            id="status"
            v-model="filterData.id_payment_statuses"
            label="title"
            :options="statusData"
            placeholder="Seçiniz"
            :reduce="status => status.id"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Firma Kullanıcısı"
          label-for="id_customer_users"
        >
          <v-select
            id="id_customer_users"
            v-model="filterData.id_customer_users"
            label="title"
            :options="customerUsers"
            placeholder="Seçiniz"
            :reduce="item => item.id"
          >
            <template v-slot:option="option">
              <div class="font-weight-bold">
                {{ option.title }}
              </div>
              <div class="font-small-2 text-muted">
                {{ option.company }}
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-input-group>
            <b-form-datepicker
              id="sdate"
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
            <b-input-group-append v-if="filterData.sdate">
              <b-button
                variant="outline-secondary"
                @click="clearDate('sdate')"
              >
                <FeatherIcon icon="XIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-input-group>
            <b-form-datepicker
              id="edate"
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
            <b-input-group-append v-if="filterData.edate">
              <b-button
                variant="outline-secondary"
                @click="clearDate('edate')"
              >
                <FeatherIcon icon="XIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-2 text-center"
      >
        <b-button
          variant="primary"
          @click="getDataList"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BButton, BCard, BCol, BFormDatepicker, BFormGroup, BInputGroup, BInputGroupAppend, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCardUser',
  components: {
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormGroup,
    BFormDatepicker,
    BCard,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['paymentsHistory/filterData']
    },
    statusData() {
      return this.$store.getters['paymentStatuses/dataList']
    },
    banks() {
      return this.$store.getters['posConfigs/getPos_configs']
    },
    usersData() {
      return this.$store.getters['users/getUsers']
    },
    customerUsers() {
      return this.$store.getters['customerUsers/dataList']
    },
  },
  created() {
    this.setStatuses()
    this.getCustomerUsers()
  },
  methods: {
    clearDate(type) {
      if (type === 'sdate') {
        this.filterData.sdate = null
      }
      if (type === 'edate') {
        this.filterData.edate = null
      }
    },
    setStatuses() {
      this.$store.dispatch('paymentStatuses/getDataList')
    },
    getCustomerUsers() {
      this.$store.dispatch('customerUsers/getDataList', {
        select: [
          'customer_users.id AS id',
          'customer_users.name AS title',
          'customers.company AS company',
        ],
        where: {
          'customer_users.id_customers': this.userData.id_customers,
        },
      })
    },
  },
}
</script>
